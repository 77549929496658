.awards {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-sm {
        max-width: 172px;

        .awardsItem {
            width: 43px;
        }
    }

    &-md {
        max-width: 195px;

        .awardsItem {
            width: 55px;
        }
    }

    &-lg {
        max-width: 230px;

        .awardsItem {
            width: 58px;
        }
    }
}
