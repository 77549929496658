@import 'scss/breakpoints';

.sidebarDrawer ~ div.MuiBox-root {
    // 100vh - header height
    min-height: calc(100vh - 59px);
}

@media screen and (max-width: $md) {
    .sidebarDrawer ~ div.MuiBox-root {
        min-height: 55vh;
        width: 100%;
    }
}
