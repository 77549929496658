@import 'scss/colors';
@import 'scss/breakpoints';
@import 'scss/fonts';

.SelectPlanStep {
    .head {
        width: 100%;
        height: 90px;
        border-radius: 8px;
        background: url('/assets/img/freemium/current-plan-bg.svg');
        background-size: cover;
        padding: 1rem;
        margin-bottom: 3rem;
    }

    h4:not(.plansTitle) {
        font-family: $font-bold;
        font-size: 16px;
    }

    .header {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
    }

    .reactivate {
        font-family: $font-semibold;
        font-size: 0.875rem;
        line-height: 1.188rem;
        margin-left: auto;

        &.sm {
            display: none;
        }

        &.md {
            display: block;
            padding: 6px 16px;
        }
    }

    .currentPlan {
        &Arrow {
            margin-left: 4px;
        }

        &Details {
            display: flex;
            align-items: center;
        }
    }

    .badge {
        width: 100%;
        max-width: 145px;
        overflow: hidden;

        span {
            color: #fff;
            background-color: #343a40;
            display: inline-block;
            padding: 0.25em 0.4em;
            font-size: 75%;
            font-family: $font-bold;
            line-height: 1;
            text-align: center;
            white-space: nowrap;
            border-radius: 0.25rem;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
        }
    }

    .price {
        margin-right: 10%;
    }

    .features {
        display: flex;
        flex: auto;
        align-items: center;
        justify-content: space-around;

        &.stretched {
            justify-content: space-between;
        }
    }

    .feature {
        display: flex;
        align-items: center;
    }

    h2 {
        font-family: $font-semibold;
        font-size: 20px;
        margin-bottom: 24px;
    }

    .MuiDivider-root {
        margin: 24px 0 16px;
    }

    .enterprise {
        align-items: center;

        &Header {
            font-family: $font-bold;
            font-size: 16px;
        }

        &Text {
            font-size: 12px;
        }

        &CtaContainer {
            text-align: center;
        }
    }

    .plan {
        &Card {
            box-shadow: 0 2px 10px rgb(26 26 26 / 16%);
            border-radius: 8px;
            height: 100%;
            padding: 24px;
        }

        &Name {
            display: inline-flex;
            align-items: center;
            font-family: $font-semibold;
            font-size: 14px;
            margin-bottom: 0.5rem;

            & > img {
                margin-right: 10px;
            }
        }

        &Price {
            font-family: $font-bold;
            font-size: 32px;
        }

        &Period {
            font-family: $font-light;
            margin-left: 15px;
        }

        .cta {
            font-family: $font-semibold;
            font-size: 14px;
            padding: 8px;
            border-radius: 12px;
        }

        &Upgrade {
            background: $color-red-2;
            white-space: nowrap;

            &:hover {
                background: #c83147;
            }

            img {
                margin-right: 4px;
            }
        }

        &Features {
            margin-top: 1rem;

            &Title {
                font-family: $font-default;
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 1rem;
            }

            li {
                display: flex;
                padding-bottom: 0.5rem;

                svg {
                    margin-right: 12px;
                }

                span {
                    font-family: $font-semibold;
                    font-size: 14px;
                }
            }
        }

        &:nth-child(3n + 2) .planCard {
            box-shadow: 0 2px 10px rgb(26 26 26 / 16%), 0 -5px 1px #c8e5f9;
        }

        &:nth-child(3n + 3) .planCard {
            box-shadow: 0 2px 10px rgb(26 26 26 / 16%), 0 -5px 0 $color-blue;
        }

        &:not(:first-child) {
            li {
                span {
                    color: $color-blue-dark-2;
                }
            }
        }
    }
}

@media (max-width: $sm) {
    .SelectPlanStep {
        .head {
            height: 170px;
        }

        .reactivate {
            &.sm {
                display: block;
                margin: -20px 0 20px;
                width: 100%;
            }

            &.md {
                display: none;
            }
        }

        .currentPlanDetails {
            flex-direction: column;
        }

        .badge {
            text-align: center;
            max-width: none;
        }

        .price {
            margin-right: 0;
        }

        .features {
            flex-direction: column;
        }

        .feature {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }
}
