@import 'scss/colors';
@import 'scss/fonts';

.signUpFormGrid {
    .formControl {
        .MuiFormControl-root {
            .MuiFormHelperText-root {
                display: none;
            }
        }
    }

    .SSOActionsSeparator .line {
        &.left {
            margin-right: 16px;
        }

        &.right {
            margin-left: 16px;
        }
    }

    .error {
        display: flex;
        font-size: 12px;
        color: $color-red-alert;
        margin-top: 8px;
    }

    .errorIcon {
        flex: 0 0 20px;
        max-width: 20px;

        .MuiSvgIcon-root {
            position: relative;
            top: 2px;
            font-size: inherit;
        }
    }

    .errorLink {
        color: inherit;
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}
