$font-light: open-sans-light, sans-serif; // 300
$font-default: open-sans-regular, sans-serif; // 400
$font-semibold: open-sans-semibold, sans-serif; // 600
$font-bold: open-sans-bold, sans-serif; // 700

$font-i-light: Inter, sans-serif; // 300
$font-i-regular: Inter, sans-serif; // 400
$font-i-medium: Inter, sans-serif; // 500
$font-i-semibold: Inter, sans-serif; // 600
$font-i-bold: Inter, sans-serif; // 700

.text-default {
    font-family: $font-default;
}

.font-size-default {
    font-size: 16px;
}
