@import 'scss/colors';
@import 'scss/fonts';

.ActivationStep {
    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    .actionBack {
        display: inline-flex;
        align-items: center;
        color: $color-blue-dark-2;
        font-size: 16px;
        cursor: pointer;

        svg {
            margin-right: 5px;
            width: 18px;
            height: 18px;
        }
    }

    .details {
        &Container {
            display: flex;
            align-items: flex-start;

            img {
                width: 24px;
                margin-right: 15px;
            }
        }

        span {
            font-family: $font-bold;
            vertical-align: middle;
        }

        .bullets {
            font-size: 20px;
            letter-spacing: -1px;
        }

        .cardNumbers {
            margin-left: 0.25rem;
        }

        .expiration {
            color: #7e7e7e;
            font-size: 12px;
            line-height: 14px;
            font-family: $font-default;
            margin-bottom: 1rem;
        }
    }

    .plan {
        .text {
            font-family: $font-light;
            font-size: 14px;
            margin-bottom: 1rem;
        }

        &Name {
            display: inline-flex;
            align-items: center;
            font-family: $font-bold;
            font-size: 32px;

            img {
                margin-right: 9px;
            }
        }

        &Features {
            margin-top: 1rem;

            &Title {
                font-family: $font-default;
                font-weight: bold;
                font-size: 16px;
                margin-bottom: 1rem;
            }

            li {
                display: flex;
                padding-bottom: 0.5rem;

                svg {
                    margin-right: 12px;
                }

                span {
                    font-family: $font-semibold;
                    font-size: 14px;
                    color: $color-blue-dark-2;
                }
            }
        }

        &Price {
            font-family: $font-bold;
            font-size: 24px;
            line-height: 33px;

            &Block {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .MuiSkeleton-text {
                display: inline-block;
                margin-left: 4px;
            }
        }
    }

    .total {
        font-family: $font-semibold;
        font-size: 1.125rem;
        line-height: 1.563rem;
    }

    .period {
        font-family: $font-light;
        margin-left: 5px;
        font-size: 1.125rem;
    }

    .upgradePrice .planPrice {
        color: $color-success;
        font-family: $font-bold;
        font-size: 1.5rem;
        line-height: 2.063rem;
    }

    .fullPrice {
        margin-top: 8px;

        .total {
            color: $color-neutral-gray;
            font-size: 1rem;
            line-height: 1.375rem;
        }

        .planPrice {
            color: $color-neutral-gray;
            font-family: $font-bold;
            font-size: 1rem;
            line-height: 1.375rem;
        }

        .period {
            font-size: 1rem;
            line-height: 1.375rem;
        }

        .crossed {
            text-decoration: line-through;
        }
    }

    .paymentDetails {
        box-shadow: 0 2px 10px rgb(26 26 26 / 16%), 0 -5px 1px #c8e5f9;
        border-radius: 8px;
        padding: 19px 16px;
        margin-bottom: 1rem;

        h2 {
            font-family: $font-bold;
            font-size: 1.125rem;
            line-height: 1.563rem;
            margin: 0;
        }
    }

    .customerCreditCard {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 1rem;

        .trash {
            padding: 4px;
        }
    }

    .MuiDivider-root {
        margin: 1rem 0;
    }

    .trialNotice {
        font-family: $font-light;
        font-size: 0.875rem;
        line-height: 1rem;
        margin: 24px 0 8px;
    }

    .activateCta {
        font-family: $font-bold;
        line-height: 1.188rem;
        font-size: 0.875rem;
        width: 100%;
        padding: 8px;
        margin-top: 16px;
    }
}
