@import 'scss/colors';
@import 'scss/mixins';

.CallsToActionsForm .CallsToActionsSection .CallToActionCard {
    .header {
        display: flex;
        padding: 13px 15px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    .title {
        @include maxStringLines(1);
        display: block;
        font-family: Inter, sans-serif !important;
        font-weight: 600 !important;
    }

    .btns {
        display: flex;
        gap: 10px;

        .MuiIconButton-root {
            border-radius: 10px;
        }

        .edit {
            border: 1px solid #b7cdfe;
            background: #e7eeff;
            color: $color-blue-dark-2;
        }

        .delete {
            border: 1px solid #ffd2cc;
            background: $color-red-light-2;
            color: $color-red-alert;
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        gap: 15px;
        .rowValue {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
