@import 'scss/colors';
@import 'scss/breakpoints';

.CustomContent {
    display: flex;
    align-items: center;

    .image-container {
        position: relative;
        height: 48px;
        margin-right: 16px;

        img {
            width: 86px;
            height: 48px;
            object-fit: cover;
            border-radius: 2px;
        }

        .time {
            padding: 3px 7px;
            font-size: 12px;
            color: $color-white;
            background-color: rgb(0 0 0 / 50%);
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }

    .RowActions {
        margin-left: auto;
    }

    .details {
        &-container {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .breadcrumb {
                font-size: 80%;
                font-weight: 400;
            }

            .unpublished {
                color: #f00;
                font-size: 80%;

                &.asterisk {
                    margin-right: 0.25rem;
                }

                &.text {
                    margin-left: 0.25rem;
                }
            }
        }

        &Wrapper {
            display: flex;
            flex-direction: column;

            & > .MuiTypography-root {
                color: $color-dark;

                &:last-child {
                    color: rgb(0 0 0 / 50%);
                }
            }
        }

        .plays,
        .funnel {
            display: inline-flex;
            align-items: center;

            &.MuiTypography-root::after {
                top: 0;
            }

            svg {
                font-size: 1.15rem;
                margin-left: 4px;
                margin-top: 2px;
            }
        }

        .isReady {
            font-size: 14px;
            color: $color-red-light;
        }
    }
}

@media screen and (max-width: $md) {
    .CustomContent {
        flex-wrap: wrap;

        .details {
            &-container {
                flex-basis: 100%;
                order: 3;
                margin-top: 8px;

                span {
                    font-size: 11px;
                }
            }

            &Wrapper > span {
                font-size: 14px;
            }
        }
    }
}
