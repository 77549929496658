@import '../../scss/font-open-sans';
@import '../../scss/font-inter';
@import '../../scss/font-inter-common';
@import '../../scss/colors';
@import '../../scss/breakpoints';
@import '../../scss/fonts';

*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Disable autofilled inputs colors */
input:-webkit-autofill,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

div.intercom-lightweight-app,
div.intercom-lightweight-app-launcher {
    z-index: 1101;
}

#root {
    width: 100%;
    min-height: 100%;
}

html {
    height: 100%;
    text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    overflow: hidden;
}

html body {
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    height: 100%;
    min-width: 320px;
    margin: 0;
    overflow: auto;
    padding-right: 0;
}

a {
    color: $color-blue-medium;
    text-decoration: none;
    background-color: transparent;

    &:hover {
        color: #0056b3;
        text-decoration: underline;
    }
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

a:focus,
input:focus,
button:focus,
select:focus {
    outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
}

input[type='number'] {
    appearance: textfield;
}

img {
    vertical-align: middle;
}

.imgFluid {
    max-width: 100%;
    height: auto;
}

@media (min-width: 992px) {
    .modal-md {
        max-width: 600px;
    }
}

@media screen and (max-width: 991px) {
    .btn.btn-outline:focus {
        box-shadow: none;
    }
}

.modal-xl {
    max-width: 928px !important;
}
