@import 'scss/colors';
@import 'scss/breakpoints';

.MobileTHead .menusContainer {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    & > div {
        margin-right: 20px;
        margin-left: 12px;
    }

    .actionsContainer {
        width: 100%;
        margin: 0;

        .Menu {
            width: 100%;
        }

        & > *:not(:last-child) {
            margin-right: 10px;

            @media (max-width: $sm) {
                margin-right: 8px;
            }
        }

        button {
            width: 100%;

            &.helperButton {
                box-shadow: none;
            }
        }

        & > .Menu {
            &:first-child {
                z-index: 10;
            }

            &:last-child {
                z-index: 9;
            }
        }

        .MuiList-root {
            svg.ascendant {
                transform: scale(1, -1);
            }
        }
    }
}

@media screen and (max-width: 380px) {
    .MobileTHead .menusContainer {
        .actionsContainer {
            flex-wrap: wrap;

            & > *:not(:last-child) {
                margin-right: 0;
                margin-bottom: 10px;
            }
        }
    }
}
