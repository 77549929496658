@import 'scss/mixins';
@import 'scss/colors';
@import 'scss/breakpoints';

.StatsLockedContent {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -8px 0 24px;
    gap: 16px;

    & > .MuiBox-root {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .picture {
        padding-right: 32px;

        img {
            width: 100%;
        }
    }

    .features {
        padding-left: 32px;
    }

    .featuresItem {
        display: flex;
        gap: 16px;
        padding: 0 0 8px;

        .MuiTypography-root {
            color: $color-blue;

            @include osans-semibold(14, 20);
        }
    }
}

@media (max-width: $sm) {
    .StatsLockedContent {
        flex-direction: column;
        align-items: flex-start;

        & > .MuiBox-root {
            flex: 0 0 100%;
            max-width: 100%;
            padding: 0;
        }
    }
}
