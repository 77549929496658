@font-face {
    font-family: open-sans-semibold;
    font-display: auto;
    src: url('/assets/fonts/open-sans/fonts/OpenSans-Semibold.ttf') format('truetype'),
        url('/assets/fonts/open-sans/fonts/OpenSans-Semibold.woff') format('woff'),
        url('/assets/fonts/open-sans/fonts/OpenSans-Semibold.svg') format('svg');
}

@font-face {
    font-family: open-sans-regular;
    font-display: auto;
    src: url('/assets/fonts/open-sans/fonts/OpenSans-Regular.ttf') format('truetype'),
        url('/assets/fonts/open-sans/fonts/OpenSans-Regular.woff') format('woff'),
        url('/assets/fonts/open-sans/fonts/OpenSans-Regular.svg') format('svg');
}

@font-face {
    font-family: open-sans-bold;
    font-display: auto;
    src: url('/assets/fonts/open-sans/fonts/OpenSans-Bold.ttf') format('truetype'),
        url('/assets/fonts/open-sans/fonts/OpenSans-Bold.woff') format('woff'),
        url('/assets/fonts/open-sans/fonts/OpenSans-Bold.svg') format('svg');
}

@font-face {
    font-family: open-sans-light;
    font-display: auto;
    src: url('/assets/fonts/open-sans/fonts/OpenSans-Light.ttf') format('truetype'),
        url('/assets/fonts/open-sans/fonts/OpenSans-Light.woff') format('woff'),
        url('/assets/fonts/open-sans/fonts/OpenSans-Light.svg') format('svg');
}

@font-face {
    font-family: open-sans-extrabold;
    font-display: auto;
    src: url('/assets/fonts/open-sans/fonts/OpenSans-ExtraBold.ttf') format('truetype'),
        url('/assets/fonts/open-sans/fonts/OpenSans-ExtraBold.woff') format('woff'),
        url('/assets/fonts/open-sans/fonts/OpenSans-ExtraBold.svg') format('svg');
}
