@import 'scss/colors';

.MuiTableRow-root .MuiTableCell-root {
    &.active {
        background-color: $color-gray-1;
    }

    .MuiLink-root {
        color: inherit;
    }
}
