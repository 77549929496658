@import 'scss/colors';
@import 'scss/breakpoints';

.Folder {
    display: flex;
    cursor: pointer;

    &.MuiLink-root {
        width: 100%;
    }

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        margin-right: 16px;
        text-align: center;
        border-radius: 2px;

        svg {
            font-size: 22px;
        }
    }

    .breadcrumb {
        font-size: 80%;
        font-weight: 400;
    }

    .details {
        span:not(:last-child)::after {
            content: '';
            display: inline-block;
            position: relative;
            width: 3px;
            height: 3px;
            background-color: $color-dark;
            border-radius: 50%;
            top: -3px;
            margin-left: 7px;
            margin-right: 7px;
        }

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;

            & > span {
                color: $color-dark;
                margin-bottom: 3px;

                &:last-child {
                    color: rgb(0 0 0 / 50%);
                }
            }
        }

        &-folder-edit {
            .MuiOutlinedInput-root.MuiInputBase-root {
                pointer-events: none;

                &.Mui-focused {
                    pointer-events: initial;
                }

                &::before {
                    border: none;
                }
            }

            &.isSelected {
                .MuiFormControl-root {
                    border-radius: 0;
                }

                .MuiInputBase-input {
                    background-color: rgb(77 153 209 / 10%);
                }
            }

            label {
                margin-left: 11px;
                cursor: pointer;
            }

            input {
                width: 100%;
            }

            .resizeHelper {
                font-size: 1rem;
            }
        }
    }

    div.RowActions {
        justify-content: flex-start;
        margin-left: auto;

        a,
        button {
            opacity: 0;
            pointer-events: none;
        }

        @media (max-width: $md) {
            .actionsCtasContainer,
            .shareCTA {
                display: none;
            }
        }
    }
}

@media screen and (max-width: $sm) {
    .Folder {
        .details {
            &-container {
                width: 100%;

                & > div {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .MuiInput-root.MuiInputBase-root {
                    width: 100%;
                }

                .MuiTextField-root {
                    width: 100%;
                }

                input {
                    width: 100% !important;
                }
            }
        }
    }
}
