@import 'scss/fonts';
@import 'scss/colors';

.SuccessMessage {
    padding: 12px 4px 20px;

    .closeContainer {
        text-align: right;
    }

    .checkmark {
        margin-top: 12px;
        text-align: center;
    }

    .mainTitle {
        text-align: center;
        margin: 19px 0 32px;
        font-family: $font-default;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        color: #000;
    }

    .title {
        font-family: $font-bold;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        color: $color-dark;
        margin-bottom: 8px;
    }

    .desc {
        font-family: $font-default;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: $color-dark;
        margin-bottom: 24px;
    }

    .buttonContainer {
        display: flex;
        justify-content: center;
        margin-top: 8px;

        button {
            padding: 4px 80px;
        }
    }
}
