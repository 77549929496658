@import 'scss/breakpoints';

.EditableName {
    display: flex;
    align-items: center;

    input {
        letter-spacing: 0;
    }

    input,
    .resizeHelper {
        max-width: 530px;

        @media (max-width: $lg) {
            max-width: 350px;
        }

        @media (max-width: 700px) {
            max-width: 260px;
        }

        @media (max-width: 460px) {
            max-width: 180px;
        }

        @media (max-width: 460px) {
            max-width: 100px;
        }
    }

    .resizeHelper {
        opacity: 0;
        position: absolute;
    }
}
