@import 'scss/colors';

.Table .SortBy {
    display: flex;
    align-items: center;
    margin-left: auto;

    .sortOperationsContainer {
        display: flex;
        background: transparent;
        border: 1px solid $color-gray-light-5;
        border-radius: 3px;
        margin-left: 10px;

        .sortOperation {
            display: flex;
            align-items: center;
            border-radius: unset;
            padding: 5px 10px;
            cursor: pointer;

            &:hover,
            &:focus,
            &.ascendant,
            &.descendant {
                background-color: rgb(77 154 209 / 10%);
            }

            &:not(:last-child) {
                border: none;
                border-right: 1px solid $color-gray-light-5;
            }

            svg {
                font-size: 18px;
                margin-left: 4px;
                margin-top: 1px;

                &.ascendant {
                    transform: scale(1, -1);
                }
            }
        }
    }
}
