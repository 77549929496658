@import 'scss/colors';
@import 'scss/mixins';

.ManagementSection {
    padding: 20px 20px 40px;

    .MuiTypography-root {
        @include inter-reg(16, 22);

        color: $color-dark-neutrals;

        &.titleLabel {
            font-family: Inter, sans-serif!important;
            font-weight: 600;

            color: $color-dark;
        }
    }

    .MuiGrid-item {
        display: grid;
        gap: 8px;
        padding: 16px 0;
    }

    .MuiDivider-root.MuiDivider-fullWidth {
        margin: 0;
        border-color: $color-gray-light-3;
    }
}

.VidSettings .Tabs .ManagementSection {
    border-bottom: 1px solid $color-gray-2;
}
