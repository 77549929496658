@import 'scss/colors';
@import 'scss/fonts';
@import 'scss/breakpoints';

.signUp {
    &FormBottom {
        margin-top: -18px;
    }

    .ChargifyCreditChargifyForm {
        min-height: 280px;
    }

    .ChargifyCreditChargifyForm .MuiGrid-item iframe {
        width: 100% !important;
        min-height: 100px !important;
    }

    .creditCardContainer,
    .additionalDetailsContainer {
        margin-top: -40px;
    }
}

@media (max-width: $sm) {
    .signUp {
        #chargify-zip {
            padding-top: 0;
            margin-top: -20px;
        }

        #chargify-lastname {
            margin-top: -20px;
        }
    }
}
