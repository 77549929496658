@import 'scss/colors';
@import 'scss/breakpoints';

.Subscription {
    .container {
        background-color: $color-white;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;
        max-height: 98%;
        background-clip: padding-box;
        border-radius: 0.3rem;
        outline: 0;
        overflow: auto;
    }

    .body {
        position: relative;
        flex: 1 1 auto;
        padding: 8px;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 24px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1;
        color: $color-dark;
        text-shadow: 0 1px 0 $color-white;
        opacity: 0.5;
        cursor: pointer;
    }

    &.disabledExitAnimation .container {
        animation-name: exit-disabled;
        animation-duration: 0.6s;
    }

    &.isUserInactive {
        .container {
            max-height: 93%;
        }
    }

    &.hasParent {
        z-index: 15;
        position: absolute;

        .MuiBackdrop-root {
            position: absolute;
        }
    }
}

@media (max-width: $md) {
    .Subscription.isUserInactive .container {
        max-height: 90%;
    }
}

@keyframes exit-disabled {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}
