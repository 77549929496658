@import 'scss/colors';
@import 'scss/breakpoints';
@import 'scss/fonts';

.actionsContainer {
    display: flex;
    align-items: center;

    .addButton svg {
        font-size: 1.3rem;
    }

    .Menu div {
        z-index: 1;
    }
}

.Actions {
    display: flex;

    .actionsContainer {
        width: 100%;
    }

    .SortBy > span {
        white-space: nowrap;
    }
}

@media screen and (max-width: $md) {
    .actionsContainer {
        margin-top: 20px;
        margin-bottom: 28px;

        .MuiTextField-root {
            width: 100%;
            max-width: initial;
            margin: 0;
        }
    }
}
