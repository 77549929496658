@import 'scss/fonts';
@import 'scss/colors';

.VideosWarning {
    padding: 12px 4px 20px;

    .closeContainer {
        text-align: right;
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: $font-semibold;
        font-weight: 600;
        font-size: 24px;
        line-height: 33px;
        color: $color-red;

        svg {
            margin-right: 24px;
        }
    }

    .description {
        font-family: $font-semibold;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: $color-dark;
        margin: 24px 0 32px;
    }

    .buttonsContainer {
        display: flex;
        justify-content: center;
        margin-top: 8px;

        button {
            width: 100%;
            max-width: 200px;

            &:first-child {
                margin-right: 24px;
            }
        }
    }
}
