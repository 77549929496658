$color-blue-extra-light-2: #edf4fa;
$color-blue: #4d9ad1;
$color-blue-2: #5bc0de;
$color-blue-3: #4183b1;
$color-blue-4: #1e9ad6;
$color-blue-5: #337ab7;
$color-blue-6: #4081fb;
$color-blue-7: #f6fafd;
$color-blue-8: #55afef;
$color-blue-9: #376eff;
$color-blue-10: #84caea;
$color-blue-11: #1976d2;
$color-blue-dark: #1884af;
$color-blue-dark-2: #1059fb;
$color-blue-dark-3: #6594fc;
$color-blue-navy: #2e3b54;
$color-blue-medium: #007bff;
$color-blue-text: #0445d7;
$color-blue-label: #bdd1fe;
$color-white: #fff;
$color-white-2: #f5f9fc;
$color-gray-light-1: #fefefe;
$color-gray-light-2: #eee;
$color-gray-light-3: #ededed;
$color-gray-light-4: #ced4da;
$color-gray-light-5: #cdcfd2;
$color-gray-light-6: #f0f4f7;
$color-gray-light-7: #dee2e6;
$color-gray-light-8: #ffecee;
$color-neutral-gray: #949494;
$color-gray: #cecece;
$color-gray-1: #ccc;
$color-gray-2: #dedfe2;
$color-gray-3: #b8b8b8;
$color-gray-dark: #c5c4c2;
$color-gray-dark-2: #666;
$color-gray-dark-3: #6c757d;
$color-success: #00ac42;
$color-green: #33b900;
$color-green-2: #3c763d;
$color-warning: #d8b500;
$color-error: #dc3545;
$color-danger: #a94442;
$color-red: #d80027;
$color-red-alert: #ff1f00;
$color-red-dark: #d32f2f;
$color-red-light: #ff4728;
$color-red-light-2: #ffecef;
$color-red-2: #fb3e59;
$color-red-3: #cb491c;
$color-red-4: #c83147;
$color-cta-main: #f06292;
$color-cta-dark: #ec407a;
$color-cta-light: #f48fb1;
$color-dark: #000;
$color-black: #212529;
$color-dark-blue: #1b2432;
$color-dark-neutrals: #333;

.text-green {
    color: $color-green;
}

.vidalytics-success {
    color: $color-green-2;
}

.text-blue {
    color: $color-blue;
}
