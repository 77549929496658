@import 'scss/colors';
@import 'scss/fonts';
@import 'scss/breakpoints';
@import 'scss/common';
@import 'scss/mixins';

.ReplaceVidSection {
    padding: 0 24px 40px 20px;

    .ReplaceVidSectionVideosList {
        border: 1px solid $color-gray-2;
        border-radius: 10px;
        margin-top: 10px;
        min-height: 245px;
        max-height: 245px;
        overflow-y: scroll;
    }

    .MuiInputAdornment-root svg {
        color: rgb(0 0 0 / 42%);
    }

    h3 {
        white-space: initial;
        font-size: 1.5rem;
    }

    .MuiTypography-root {
        white-space: normal;
    }

    .MuiList-root {
        padding: 0;
        margin: 0;
        overflow-y: auto;

        .MuiListItemText-root span {
            white-space: initial;
        }

        .video {
            &Details {
                display: flex;
                flex: 1;
                justify-content: space-between;

                .MuiListItemText-root {
                    flex: initial;
                    min-width: 50px;
                    text-align: right;
                }

                span {
                    font-size: 12px;

                    @include maxStringLines(1);
                }
            }
        }

        img {
            width: 54px;
            height: 30px;
            object-fit: cover;
            margin-right: 10px;
            border-radius: 2px;
        }
    }

    .action {
        margin-top: 20px;
    }

    .loaderSection {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 10px;
        margin-top: 10px;
        min-height: 220px;
    }

    .emptyList {
        height: 100%;
        min-height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .MuiTypography-root {
            font-size: 1rem;
            text-align: center;

            &.highlighted {
                font-family: $font-semibold;
                font-size: 1.125rem;
                margin-bottom: 8px;
            }
        }
    }

    .skeleton {
        .MuiSkeleton-root {
            border-radius: 2px;
        }

        .MuiSkeleton-rectangular {
            margin-left: 26px;
        }

        .MuiBox-root {
            width: calc(100% - 130px);
            margin-left: 10px;
        }
    }

    .MuiButtonBase-root {
        padding: 15px 20px;
        cursor: pointer;
    }

    .selected {
        background-color: #edf4fa !important;
    }
}
