@import 'scss/colors';
@import 'scss/breakpoints';

.ConversionsLockedContent {
    text-align: left;

    .title {
        &.MuiTypography-root {
            font-size: 16px;
            line-height: 1.375;
            margin-top: 0;
            margin-bottom: 18px;
        }
    }

    .features {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -16px 28px;
    }

    .featuresColumnLeft {
        flex: 0 0 58.3%;
        max-width: 58.3%;
    }

    .featuresColumnRight {
        flex: 0 0 41.7%;
        max-width: 41.7%;
    }

    .featuresColumnLeft,
    .featuresColumnRight {
        padding-left: 15px;
        padding-right: 15px;

        @media (max-width: $md) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .featureItem {
        position: relative;
        color: $color-blue-dark-2;
        font-weight: 700;
        line-height: 1.357;
        margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .featureItemWrapper {
        display: flex;
        gap: 12px;
        align-items: center;
    }

    .subfeatureList {
        list-style: disc;
        padding-left: 36px;
        margin: 14px 0 16px;
    }

    .subfeatureItem {
        color: $color-dark;
        font-weight: 400;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
