@font-face {
    font-family: Inter-Light;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: Inter-Regular;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: Inter-Medium;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: Inter-SemiBold;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: Inter-Bold;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: Inter-ExtraBold;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-ExtraBold.ttf') format('truetype');
}
