@import 'scss/fonts';
@import 'scss/colors';
@import 'scss/breakpoints';

.Menu {
    display: flex;
    align-items: center;

    .ariaButton {
        white-space: nowrap;

        @media (max-width: $sm) {
            padding: 4px 8px;
        }

        svg {
            transform: rotate(90deg);
            font-size: 12px;
            margin-left: 8px;
            margin-top: 2px;
        }
    }

    .paper {
        border-radius: 12px;

        .MuiList-root {
            margin-top: 8px;
            padding: 0;
            border-radius: 12px;
            overflow: hidden;
        }

        .MuiMenuItem-root {
            font-size: 14px;
            font-family: $font-default;
        }
    }
}
