@import 'scss/colors';
@import 'scss/mixins';

.GlobalSettings .SmartAutoplayContent,
.VidSettings .Tabs .SmartAutoplayContent {
    padding: 10px 0 20px 20px;

    & > .Switch {
        padding-right: 20px;
    }

    .autoplayMessageStyle {
        margin: 20px 0 15px;
    }

    .topText {
        margin-bottom: 20px;
    }

    .container {
        padding: 0 20px 0 10px;
    }

    .colorsContainer {
        display: grid;
        gap: 15px;
    }

    .overlayInputs {
        padding: 0 0 16px;
    }

    .overlayInputs,
    .mobileOverlayInputs {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .MuiPaper-root.MuiPaper-rounded.sectionSubAccordion {
        border: none;
        margin: 0;

        .MuiAccordionSummary-root {
            padding: 0 20px 0 0;

            .Switch {
                width: 100%;
            }
        }
    }

    .MuiPaper-root.MuiPaper-rounded.advancedOptions {
        border: none;
        margin: 0;

        &::before {
            display: none;
        }

        .MuiAccordionSummary {
            &-root {
                padding: 0;
                justify-content: flex-start;
                gap: 6px;

                .MuiTypography-root {
                    @include inter-semibold(14, 22);
                }
            }

            &-content {
                flex: 0;
            }

            &-expandIconWrapper {
                transform: rotate(-90deg);

                &.Mui-expanded {
                    transform: rotate(0);
                }
            }
        }

        .MuiAccordionDetails-root {
            display: flex;
            flex-direction: column;
            gap: 4px;
            margin-top: 4px;
            padding: 0 20px 0 10px;
        }
    }

    .advancedOptions {
        .MuiFormControlLabel-label {
            font-size: 14px;
        }
    }

    .MuiFormControlLabel-root {
        width: 100%;

        .MuiSwitch-root {
            margin-left: auto;
        }
    }

    .MuiAccordionSummary-content {
        & > .MuiBox-root {
            width: 100%;
        }
    }
}
