@font-face {
    font-family: 'Inter';
    font-weight: 300;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 400;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 500;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 600;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 700;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-weight: 800;
    font-display: auto;
    src: url('/assets/fonts/inter/fonts/Inter-ExtraBold.ttf') format('truetype');
}