@import 'scss/colors';
@import 'scss/mixins';

.VidSettings .Tabs .TagsContent {
    padding: 0 24px 40px 20px;

    .tagsHeaderText {
        margin-bottom: 20px;

        .MuiTypography-root {
            white-space: normal !important;
        }
    }

    .error {
        white-space: normal !important;
        padding-bottom: 20px;
    }

    &.MuiBox-root {
        .MuiPaper-root.MuiPaper-rounded {
            border: none;
        }

        .MuiAccordionSummary-root {
            padding: 0;
        }
    }

    .tagsCards {
        margin-top: 20px;
        margin-bottom: 20px;

        & > .MuiBox-root:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .formSection {
        padding: 5px;
        margin: 20px 0;
        border-radius: 15px;
    }

    .close {
        padding: 0;

        svg {
            width: 12px;
            height: 12px;
        }
    }

    .subSection {
        display: flex;
        flex-direction: column;
        margin: 15px;

        &Title {
            @include inter-semibold(14, 22);

            margin-bottom: 15px;
        }
    }

    .linkInNewWindow {
        margin-top: 10px;
    }

    .displayMode {
        display: grid;
    }

    .htmlSelectorTypeContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        width: 100%;

        .MuiInput-root {
            font-size: 0.875rem;
        }
    }

    .gap {
        display: grid;
        gap: 5px;
    }

    .ColorPicker input {
        background-color: transparent;
    }

    .formSectionAccordion {
        background-color: transparent;
    }

    .htmlSelectorType {
        .MuiFormControlLabel-root {
            display: flex;
            align-items: flex-start;
        }

        .formControlOutlined {
            margin-left: -15px;
        }

        .MuiFormControlLabel-label {
            width: 100%;
            margin-right: -15px;
        }
    }

    .TagType {
        .MuiTypography-root {
            width: 100%;

            & > .container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 30px;
            }
        }

        .label {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
        }

        .formControlOutlined {
            width: 70px;
        }
    }

    .tagsInputWrapper {
        position: relative;

        .tagsInput {
            position: absolute;
            left: 20px;
            top: 70px;
            z-index: 1000;
            display: flex;
            gap: 5px;
            flex-wrap: wrap;
            max-height: 93px;
            overflow-y: scroll;
            padding-right: 20px;
        }

        .tagValue {
            padding: 2px 6px;
            border: 1px solid #dedfe2;
            display: flex;
            align-items: center;
            border-radius: 6px;
            max-height: 30px;

            svg {
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }

    .tagFields {
        .MuiInputBase-input {
            height: 125px !important;
        }
    }

    .gap25 {
        display: grid;
        gap: 25px;
    }

    .ctas {
        display: grid;
        margin: 20px 15px;
        gap: 10px;
        grid-template-columns: 2fr 3fr;
    }

    .createNewTag {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}
