@import 'scss/breakpoints';
@import 'scss/fonts';
@import 'scss/colors';

.Table {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    .MuiTableContainer-root {
        margin-top: 4px;
    }

    .MuiTableCell-head {
        font-family: $font-semibold;
    }

    .MuiTableCell-root {
        &:not(.MuiTableCell-head) {
            font-family: $font-default;
        }
    }

    tr .selection {
        padding-left: 12px;
        padding-right: 4px;
    }

    td {
        transition: 0.2s;
        button{
            font-weight: 600;
        }
    }

    .MuiTableRow-root {
        &.isFolderEditing {
            touch-action: none;
            -webkit-user-drag: none;
            -webkit-tap-highlight-color: transparent;
            user-select: none;
            pointer-events: none;
        }

        &.selected td {
            background-color: rgb(77 153 209 / 10%);
        }
    }
}

@media screen and (max-width: $sm) {
    .Table thead th {
        border-bottom: none;
    }

    .Table tr .selection {
        padding-left: 0px;
    }
}
