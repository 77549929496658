@import 'scss/colors';
@import 'scss/mixins';

.VidSettings .Sidebar .Tabs,
.CallsToActionsForm .CallsToActionsSection,
.PlayGatesForm .PlayGatesSection {
    width: 100%;
    min-height: fit-content;

    .MuiTabs-root {
        min-height: auto;
    }

    .MuiTab-root {
        @include inter-medium(16, 24);

        padding: 12px 0;
        min-height: auto;
        min-width: auto;
        position: relative;
        font-weight: 600;
        text-transform: none;

    }
    .MuiTouchRipple-root {
        display: none;
    }

    .MuiPaper-root:not(.MuiAutocomplete-paper){
        border-radius: 0;
    }
    .MuiAlert-standard {
        border: 1px solid #EF6C0080 !important;
        border-radius: 12px !important;
    }

    & > .MuiBox-root > div > .MuiPaper-root {
        margin: 0;
    }

    .HelpPopperOpener {
        line-height: 0;
        color: $color-blue-dark-2;

        .MuiSvgIcon-root {
            font-size: 1rem;
            color: $color-blue-dark-2;
        }
    }

    .MuiAccordionSummary {
        &-root {
            min-height: auto;
            padding: 16px 24px 16px 20px;
        }

        &-expandIconWrapper {
            &.Mui-expanded {
                transform: rotate(90deg);
            }

            .MuiSvgIcon-root {
                font-size: 1rem;
            }
        }

        &-content {
            margin: 0;

            & > .MuiBox-root {
                display: flex;
                align-items: center;
            }
        }
    }

    .rootPanelSummary .MuiAccordionSummary-content {
        & > .MuiBox-root {
            gap: 16px;

            & > .MuiSvgIcon-root {
                font-size: 1.25rem;
            }

            & > span {
                @include inter-semibold(16, 22);

                mix-blend-mode: normal;
            }

            .proLabel {
                @include inter-bold(14, 16);

                color: $color-blue-dark-2;
                display: flex;
                align-items: center;
                gap: 3px;
            }
        }
    }

    .MuiAccordionDetails-root {
        padding: 0;
    }

    .Switch {
        .MuiFormControlLabel {
            &-label {
                @include inter-reg(14, 20);
            }

            &-root.dropdownSwitch .MuiFormControlLabel-label {
                @include inter-semibold(14, 22);
            }

            &-root {
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .ColorPicker {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .HexColorPickerContainer {
            top: 28px;
        }

        .label {
            @include inter-reg(14, 20);

            color: $color-dark;
            width: 100%;
        }

        &.disabled .label {
            color: rgb(0 0 0 / 38%);
        }

        .preview {
            width: 24px;
            height: 24px;
            margin: 0 0 0 9px;
            border: 1px solid $color-gray-2;
            border-radius: 4px;
        }

        input {
            width: 75px;
            margin: 0;
            color: $color-dark;
            text-align: right;

            @include inter-reg(14, 20);
        }

        .fieldContainer {
            flex-direction: row-reverse;
            margin: 0;
        }
    }


    .Card {
        border-radius: 15px;
        overflow: hidden;
    }

    .MuiAccordion-root::before {
        display: none;
    }
}
