@import 'scss/colors';
@import 'scss/mixins';

.VidSettings .Tabs .TagsCard {
    .header {
        display: flex;
        padding: 13px 15px;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }

    .title {
        @include inter-semibold(16, 22);
        @include maxStringLines(1);
    }

    .btns {
        display: flex;
        gap: 10px;

        .MuiIconButton-root {
            border-radius: 10px;
        }

        .edit {
            border: 1px solid #b7cdfe;
            background: #e7eeff;
            color: $color-blue-dark-2;
        }

        .delete {
            border: 1px solid #ffd2cc;
            background: $color-red-light-2;
            color: $color-red-alert;
        }
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;

        .rowTagsWrapper {
            display: flex;
            flex-wrap: wrap;
            max-width: 300px;
            gap: 10px;
        }

        .rowTag {
            border-radius: 6px;
            padding: 2px 6px;
            text-align: center;
        }
    }
}
