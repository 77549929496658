@import 'scss/colors';
@import 'scss/mixins';

.GlobalSettings .ResumePlayContent,
.VidSettings .ResumePlayContent,
.VidSettings .Tabs .PlayOptionsContent {
    padding: 10px 0 40px 20px;

    .radioTitle {
        display: flex;
        gap: 6px;

        @include inter-semibold(14, 22);

        align-items: center;
        margin-bottom: 15px;

        &--without-mb {
            margin-bottom: 0;
        }
    }

    .wrapper {
        padding: 0 20px 0 10px;
        display: grid;
        gap: 4px;
    }

    .uiOverlayRedirecUrl label {
        display: flex;
        gap: 6px;
        align-items: center;
    }

    .formSectionAccordion .MuiAccordionDetails-root {
        gap: 15px;
    }

    .resumePlayMessageAccordion {
        margin: 0;
    }

    .formSectionAccordion,
    .inputsSectionAccordion {
        display: flex;
        &.MuiPaper-root.MuiPaper-elevation {
            border: unset;

            .MuiAccordionSummary-root {
                display: none;
            }

            .MuiAccordionDetails-root {
                display: grid;
            }
        }
    }

    .resumeColors {
        display: grid;
        gap: 15px;
        margin-top: 15px;
    }
}

.GlobalSettings .ResumePlayContent {
    .formSectionAccordion,
    .inputsSectionAccordion {
        display: unset;
    }
    .wrapper {
        padding: 0 10px 0 0;
    }
}
