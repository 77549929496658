@import 'scss/colors';
@import 'scss/mixins';

.PlayGatesForm .PlayGatesSection .PlayGateContent {
    padding: 10px 20px 40px;

    &.MuiBox-root {
        .MuiPaper-root.MuiPaper-rounded {
            border: none;
            box-shadow: none;
        }

        .MuiAccordionSummary-root {
            padding: 0;
        }
    }

    .error {
        color: #d32f2f;
        margin-left: 20px;
        white-space: normal !important;
        margin-bottom: 20px;
        font-size: 0.75rem;
    }

    .PlayGateCards {
        margin-bottom: 20px;

        & > .MuiBox-root:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .formSection {
        padding-top: 5px;
        border-radius: 15px;
    }

    .subSection {
        display: flex;
        flex-direction: column;
        margin: 15px;

        &Title {
            @include inter-semibold(14, 22);

            margin-bottom: 15px;
        }
    }

    .linkInNewWindow {
        margin-top: 10px;
    }

    .marginLeft {
        margin-bottom: 15px;
    }

    .displayMode {
        display: grid;
    }

    .htmlSelectorTypeContainer {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 15px;
        width: 100%;

        .MuiInput-root {
            font-size: 0.875rem;
        }
    }

    .gap {
        display: grid;
        gap: 10px;
    }

    .ColorPicker input {
        background-color: transparent;
    }

    .formSectionAccordion {
        background-color: transparent;
        margin: 0;
    }

    .htmlSelectorType {
        .MuiFormControlLabel-root {
            display: flex;
            align-items: flex-start;
        }

        .formControlOutlined {
            margin-left: -15px;
        }

        .MuiFormControlLabel-label {
            width: 100%;
            margin-right: -15px;
        }
    }

    .callToActionType {
        .MuiTypography-root {
            width: 100%;

            & > .container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 30px;
                width: 100%;
            }
        }

        .label {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
        }

        .formControlOutlined {
            width: 70px;
        }
    }

    .gap25 {
        display: grid;
        gap: 25px;
    }

    .ctas {
        display: grid;
        margin: 20px 15px;
        gap: 10px;
        grid-template-columns: 2fr 3fr;
    }

    .switchMargin {
        margin-bottom: 20px;
    }

    .dividerSection {
        margin: 16px auto;
    }
}
