@import 'scss/colors';
@import 'scss/mixins';

.GlobalSettings .ExpireVideoContent,
.VidSettings .Tabs .ExpireVideoContent {
    padding: 10px 20px 40px;

    .MuiButtonBase-root.MuiAccordionSummary-root {
        padding: 0;
    }

    .MuiAccordion-root.MuiPaper-root.MuiPaper-rounded {
        border: unset;
    }

    .MuiAccordionSummary-content {
        .infoContainer.MuiBox-root {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            min-width: 100%;

            .info {
                white-space: normal;

                @include inter-reg(14, 20);
                svg {
                    margin-bottom: -3px;
                }
            }

            .HelpPopperOpener {
                line-height: 0;
                vertical-align: middle;
                margin-left: 6px;
            }
        }
    }

    .Switch {
        width: 100%;

        .expireOnSetDate .MuiTypography-root {
            @include inter-semibold(14, 22);
        }
    }

    .MuiAccordionDetails-root {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .openInANewTab {
        margin-top: -5px;
    }
}
