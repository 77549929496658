@import 'scss/colors';
@import 'scss/mixins';

.CallsToActionsForm .CallsToActionsSection .CallsToActionsContent {
    padding: 10px 20px 40px;

    &.MuiBox-root {
        .MuiPaper-root.MuiPaper-rounded {
            border: none;
            margin: 0;
            box-shadow: none;
        }

        .MuiAccordionSummary-root {
            padding: 0;
        }
    }

    .callToActionCards {
        margin-bottom: 20px;

        & > .MuiBox-root:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    .formSection {
        padding-top: 5px;
        border-radius: 15px;
    }

    .subSection {
        display: flex;
        flex-direction: column;
        margin: 15px;

        &.noMarginTop {
            margin-top: 0;
        }

        &Title {
            @include inter-semibold(14, 22);
        }
    }

    .linkInNewWindow {
        margin-top: 10px;
    }

    .marginLeft {
        margin-left: 10px;
    }

    .displayMode {
        display: grid;
    }

    .htmlSelectorTypeContainer {
        display: flex;
        align-items: center;
        gap: 15px;
        width: 100%;
    }

    .gap {
        display: grid;
        gap: 10px;
    }

    .ColorPicker input {
        background-color: transparent;
    }

    .formSectionAccordion {
        background-color: transparent;
        margin: 0;
    }

    .htmlSelectorType {
        .MuiFormControlLabel-root {
            display: flex;
            align-items: center;
        }

        .formControlOutlined {
            margin-left: -15px;
        }

        .MuiFormControlLabel-label {
            width: 100%;
            margin-right: -15px;
        }
    }

    .callToActionType {
        .MuiTypography-root {
            width: 100%;

            & > .container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 30px;
                width: 100%;
            }
        }

        .label {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;
        }

        .MuiFormControlLabel-root:first-child {
            align-items: flex-start;

            .container {
                align-items: flex-start;
            }

            .label {
                align-items: center;
                margin-top: 11px;
            }
        }

        .MuiFormHelperText-root.Mui-error {
            max-width: 150px;
            white-space: initial;
            margin: 0 auto;
        }

        .formControlOutlined {
            width: 70px;
        }
    }

    .gap40 {
        display: grid;
        gap: 35px;
        margin-top: 12px;
    }

    .ctas {
        display: grid;
        margin: 5px 15px 20px;
        gap: 10px;
        grid-template-columns: 2fr 3fr;
    }

    .displayMode {
        margin-top: 12px;
    }

    .alignment {
        padding-top: 12px;
    }

    .dividerSection {
        margin: 16px auto;
    }
}
