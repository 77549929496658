@import 'scss/fonts';
@import 'scss/common';

/* stylelint-disable */
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }
}

@mixin maxStringLines($linesCount) {
    -webkit-line-clamp: $linesCount;
    line-clamp: $linesCount;
    overflow: hidden;
    text-overflow: ellipsis;
    display: $webkit-box;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

@mixin toRem($property, $value) {
    #{$property}: calc($value / 16) + rem;
}

@mixin fontSettings($font-family) {
    font-family: $font-family;
    letter-spacing: 0em;
}

// !-- OPEN SANS

// Open Sans Regular
@mixin osans-reg($font-size, $line-height) {
    @include fontSettings($font-default);
    @include toRem(font-size, $font-size);
    @include toRem(line-height, $line-height);
}

// Open Sans SemiBold
@mixin osans-semibold($font-size, $line-height) {
    @include fontSettings($font-semibold);
    @include toRem(font-size, $font-size);
    @include toRem(line-height, $line-height);
}

// Open Sans Bold
@mixin osans-bold($font-size, $line-height) {
    @include fontSettings($font-bold);
    @include toRem(font-size, $font-size);
    @include toRem(line-height, $line-height);
}

// !-- Inter

// Inter Regular
@mixin inter-reg($font-size, $line-height) {
    @include fontSettings($font-i-regular);
    font-weight: 400;
    @include toRem(font-size, $font-size);
    @include toRem(line-height, $line-height);
}

// Inter Medium
@mixin inter-medium($font-size, $line-height) {
    @include fontSettings($font-i-medium);
    font-weight: 500;
    @include toRem(font-size, $font-size);
    @include toRem(line-height, $line-height);
}

// Inter Semibold
@mixin inter-semibold($font-size, $line-height) {
    @include fontSettings($font-i-semibold);
    font-weight: 600;
    @include toRem(font-size, $font-size);
    @include toRem(line-height, $line-height);
}

// Inter Bold
@mixin inter-bold($font-size, $line-height) {
    @include fontSettings($font-i-bold);
    font-weight: 700;
    @include toRem(font-size, $font-size);
    @include toRem(line-height, $line-height);
}
