@import 'scss/colors';
@import 'scss/breakpoints';

.MyVids {
    .RowActions {
        display: flex;
        align-items: center;

        .actionsCtasContainer {
            display: flex;
        }

        a {
            text-decoration: none;


            &.disabled {
                pointer-events: none;
                cursor: initial;
            }
        }
    }
}

@media (max-width: $sm) {
    .MyVids {
        .RowActions {
            flex-direction: column;
        }
    }
}
