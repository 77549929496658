@import 'scss/fonts';
@import 'scss/colors';

.ConfirmDowngrade {
    padding: 12px 4px 20px;

    .closeContainer {
        text-align: right;
    }

    .warning {
        margin-top: 12px;
        text-align: center;
    }

    .mainTitle {
        font-family: $font-bold;
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: $color-dark;
        margin: 16px 0 32px;
    }

    .title {
        font-family: $font-semibold;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $color-dark;
        margin-bottom: 16px;
    }

    .feature {
        display: flex;
        align-items: flex-start;
        gap: 4px;

        &:not(:last-child) {
            margin-bottom: 12px;
        }

        span {
            font-family: $font-semibold;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: $color-blue-dark-2;
        }

        img {
            margin-right: 8px;
        }
    }

    .buttonsContainer {
        display: flex;
        justify-content: center;
        margin-top: 32px;

        button {
            width: 100%;
            max-width: 200px;

            &:first-child {
                margin-right: 24px;
            }
        }
    }
}
